import React, { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import { Calendar } from 'lucide-react';
import moment from 'moment';
import styled from 'styled-components';

// Styled components for custom styling
const DatePickerWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 10px;
  top: 47%;
  transform: translateY(-50%);
  z-index: 10;
  color: rgb(90, 90, 90);
  pointer-events: none;
`;

// Styled wrapper to customize the react-dates components
const StyledDatePickerWrapper = styled.div`
  .SingleDatePickerInput {
    border-radius: 8px;
    border: 1px solid rgb(229, 231, 235);
    background-color: rgb(255, 255, 255);
    width: 100%;
    box-shadow: none;
  }

  .DateInput {
    margin-left: 2rem;
    border-radius: 8px;
  }

  .DateInput_input {
    font-size: 14px;
    padding: 4px 4px 4px 0;
    border: none;
    width: 90%;
    color: rgb(90, 90, 90);
  }

  .SingleDatePickerInput__withBorder {
    border-radius: 8px;
  }

  .SingleDatePicker,
  .SingleDatePickerInput {
    width: 100%;
  }
`;

const OptimizedDateFilter = ({
  value = [],
  onChange,
  operator = 'LT', // 'before', 'onOrBefore', 'on', 'after', 'onOrAfter', 'between'
}) => {
  const [startDate, setStartDate] = useState(value[0] ? moment(value[0]) : null);
  const [isFocussed, setIsFocussed] = useState(false);

  const handleStartDateChange = (date) => {
    setStartDate(date);

    onChange([date ? date.format('YYYY-MM-DD') : null]);
  };

  const getPlaceholderText = () => {
    switch (operator) {
      case 'LT':
        return 'Before...';
      case 'LTE':
        return 'On or before...';
      case 'EQ':
        return 'Exact date...';
      case 'GT':
        return 'After...';
      case 'GTE':
        return 'On or after...';
      default:
        return 'Select date...';
    }
  };

  return (
    <DatePickerWrapper className="tw-w-full">
      <IconWrapper>
        <Calendar size={16} />
      </IconWrapper>

      <StyledDatePickerWrapper>
        <SingleDatePicker
          date={startDate}
          focused={isFocussed}
          onDateChange={handleStartDateChange}
          onFocusChange={({ focused }) => setIsFocussed(focused)}
          id="date_input"
          placeholder={getPlaceholderText()}
          isOutsideRange={() => false}
          numberOfMonths={1}
          hideKeyboardShortcutsPanel
          customInputIcon={null}
          noBorder
          block
        />
      </StyledDatePickerWrapper>
    </DatePickerWrapper>
  );
};

export default OptimizedDateFilter;
