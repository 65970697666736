import React, { useState, useEffect } from 'react';
import { SlidersHorizontal } from 'lucide-react';
import { ModalPopup } from '@Base/Modal';
import FilterContent from './FilterContent';
import Tippy from '@tippyjs/react';

const AdvancedFilters = ({
  filters = {},
  filterData = {},
  loadingFilters = [],
  onAddFilter,
  onFilterChange,
  activeFilters = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [localActiveFilters, setLocalActiveFilters] = useState(activeFilters);
  const [editingFilter, setEditingFilter] = useState(null);

  useEffect(() => {
    if (isOpen) {
      setLocalActiveFilters(activeFilters);
      setEditingFilter(null);
    }
  }, [isOpen]);

  const handleLoadFilterOptions = async (filterType, nId) => {
    const filter = filters[filterType];

    if (filterData[filterType] || !filter.loadOptions) {
      return;
    }

    try {
      await onAddFilter?.(filterType, nId);
    } catch (error) {
      console.error(`Failed to load options for ${filterType}:`, error);
    }
  };

  const handleAddFilter = async (filterType) => {
    const nId = `${filterType}-${Date.now()}`;
    setEditingFilter(nId);
    const defaultOperator = filters[filterType].optionsAllowed?.[0] || 'EQ';

    setLocalActiveFilters([
      ...localActiveFilters,
      {
        id: nId,
        type: filterType,
        operator: defaultOperator,
        value: [],
      },
    ]);
    await handleLoadFilterOptions(filterType, nId);
  };

  const handleRemoveFilter = (filterId) => {
    const newFilters = [...localActiveFilters].filter((a) => a.id !== filterId);
    setEditingFilter(null);
    setLocalActiveFilters(newFilters);
  };

  const handleUpdateFilter = (filterId, updates) => {
    const trrt = localActiveFilters.reduce((acc, cur) => {
      if (cur.id === updates.id) {
        acc.push(updates);
      } else {
        acc.push(cur);
      }
      return acc;
    }, []);
    setLocalActiveFilters(trrt);
  };

  const handleApplyFilters = () => {
    onFilterChange(localActiveFilters);
    setIsOpen(false);
  };

  const activeFiltersCount = activeFilters.length;
  const hasActiveFilters = activeFiltersCount > 0;

  // Generate tooltip content based on active filters
  const generateTooltipContent = () => {
    if (!hasActiveFilters) return null;

    // Helper function to get human-readable operator text
    const getOperatorText = (operator) => {
      const operatorMap = {
        EQ: 'is',
        NEQ: 'is not',
        CONTAINS: 'contains',
        NOT_CONTAINS: 'does not contain',
        GT: 'greater than',
        GTE: 'greater than or equal to',
        LT: 'less than',
        LTE: 'less than or equal to',
        IN: 'is any of',
        NIN: 'is none of',
        BETWEEN: 'is between',
      };

      return operatorMap[operator] || operator;
    };

    const activeFiltersSummary = activeFilters.map((filter) => {
      const filterType = filters[filter.type]?.label || filter.type;
      const operatorText = getOperatorText(filter.operator);
      let filterValue = '';

      if (Array.isArray(filter.value) && filter.value.length > 0) {
        const filterOptions = filterData[filter.type]?.options || [];
        const selectedLabels = filter.value
          .map((val) => filterOptions.find((opt) => opt.value === val)?.label || val)
          .filter(Boolean);

        filterValue = selectedLabels.join(', ');
      } else if (typeof filter.value === 'string') {
        filterValue = filter.value;
      }

      return { filterType, operatorText, filterValue };
    });

    return (
      <div className="tw-text-sm">
        <div className="tw-font-medium tw-mb-1 tw-pb-1 tw-border-b tw-border-gray-200">Active Filters</div>
        <div className="tw-max-h-60 tw-overflow-y-auto">
          {activeFiltersSummary.map((filter, index) => (
            <div key={index} className="tw-mb-1.5 last:tw-mb-0">
              <span className="tw-font-medium">{filter.filterType}</span>{' '}
              <span className="tw-text-gray-600 tw-italic">{filter.operatorText}</span>{' '}
              <span className="tw-text-gray-800">{filter.filterValue || '-'}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const tooltipContent = generateTooltipContent();

  return (
    <>
      <Tippy
        content={tooltipContent}
        disabled={!hasActiveFilters}
        arrow={false}
        placement="bottom-start"
        className="!tw-bg-white !tw-text-gray-900 !tw-shadow-lg !tw-border !tw-px-3 !tw-py-2 !tw-rounded-lg"
        maxWidth={350}
        interactive={true}
      >
        <button
          onClick={() => setIsOpen(true)}
          className={`advanced-filters-button tw-flex tw-items-center tw-gap-1.5 tw-px-3 tw-py-1.5 tw-text-sm tw-text-gray-600 hover:tw-bg-gray-100 tw-rounded-lg ${
            hasActiveFilters ? 'tw-bg-[#1A2E56]/25 tw-border tw-border-[#1A2E56]/10' : 'tw-bg-gray-100'
          }`}
        >
          <SlidersHorizontal
            className={`tw-h-4 tw-w-4 ${hasActiveFilters ? 'tw-text-[#1A2E56]/60' : 'tw-text-gray-500'}`}
          />
          <span
            className={`${hasActiveFilters ? 'tw-font-medium tw-text-[#1A2E56]' : 'tw-font-normal tw-text-gray-700'}`}
          >
            Advanced filters
          </span>
          {hasActiveFilters && <span className="tw-ml-0.5 tw-text-[#1A2E56]/70">({activeFiltersCount})</span>}
        </button>
      </Tippy>

      <ModalPopup
        isOpen={isOpen}
        onToggle={() => setIsOpen(false)}
        title="All Filters"
        size="lg"
        onOkay={handleApplyFilters}
        submitText="Apply Filters"
        cancelText="Cancel"
        customBodyClass="tw-p-0"
      >
        <FilterContent
          activeFilters={localActiveFilters}
          editingFilter={editingFilter}
          filters={filters}
          filterData={filterData}
          loadingFilters={loadingFilters}
          onSelectFilter={setEditingFilter}
          onAddFilter={handleAddFilter}
          onRemoveFilter={handleRemoveFilter}
          onUpdateFilter={handleUpdateFilter}
          setEditingFilter={setEditingFilter}
        />
      </ModalPopup>
    </>
  );
};

export default AdvancedFilters;
